import React from "react";
import { Link } from "gatsby";
import { Layout, Row, Col, BackTop, Button } from "antd";
import { Helmet } from "react-helmet";

import CommonHeader from "../../components/common/header";
import CommonFooter from "../../components/common/footer";
import UseCaseSider from "../../components/useCases/use-cases-sider";

const UseCaseCareerPlanner = ({}) => (
  <div>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Online Career Planner</title>
      <meta
        name="description"
        content="DayViewer makes an ideal Career Planning Tool. When you are planning your next career move or just putting yourself out there in the market - it's important to stay organized and plan applications, set interview appointments and keep related notes all in one place."
      />
    </Helmet>
    <Layout>
      <CommonHeader />
      <Layout style={{ minHeight: "100vh" }}>
        <BackTop />
        <UseCaseSider />

        <Layout className="guide-body">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h1>Plan Applications and Organize Time Effectively</h1>

            <Button className="homepage-signup-btn">
              <a
                href="#"
                onClick={() =>
                  window.open("https://app.dayviewer.com/auth/signup", "_self")
                }
              >
                Sign Up
              </a>
            </Button>
          </div>

          <Row>
            <div>
              <h2>
                Organize your CV / Resume &amp; variations, plan applications
                and keep on top of your schedule for interviews and
                appointments.
              </h2>
            </div>
            <Col>
              <p>
                When applying for new positions, it is highly important to keep
                everything organized. DayViewer provides all the necessary
                components to help you land that amazing job.
              </p>
              <p>
                When you have a number of interviews lined up, what better way
                than to use an online calendar to note them down in and get a
                reminder about!
              </p>
              <p>
                Attach extra information to each entry detailing any notes, or
                even prepare interview checklists to each entry to give yourself
                the best chance.
              </p>
              <p>
                We have all been there, making thousands of tweaks to our CV /
                Resume tailored for specific roles. Attach your resumes and CVs
                to notes which describe exactly which type of job they are
                tailored to.
              </p>
              <p>
                Getting a job is in itself a professional vocation these days
                and nothing stands out more than a candidate totally prepared.
              </p>

              <div
                style={{
                  textAlign: "center",
                  padding: 20,
                }}
              >
                <picture>
                  <source
                    type="image/webp"
                    srcSet="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/reminder-640.webp"
                  ></source>
                  <source
                    type="image/jpg"
                    srcSet="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/reminder-640.jpg"
                  ></source>
                  <img
                    src="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/reminder-640.jpg"
                    alt="career planner"
                    title="career planner"
                  />
                </picture>
              </div>
            </Col>
          </Row>
        </Layout>
      </Layout>
      <CommonFooter />
    </Layout>
  </div>
);

export default UseCaseCareerPlanner;
